.projects-container {
  overflow-x: auto;
  background: var(--bg-color);
  width: 100%;
  height: 100vh;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  position: relative;
  transition: all 0.5s ease;
}

.projects-container::-webkit-scrollbar {
  display: none;
}

.sections-wrapper {
  display: flex;
  width: 300vw;
  height: 100%;
  position: relative;
  transition: transform 0.5s ease-in-out;
}

.servicios-verticales-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2vw 8vw;
  background: var(--bg-color);
  color: var(--text-color);
  width: 100vw;
  height: 100%;
  flex-shrink: 0;
  scroll-snap-align: center;
  opacity: 0.7;
  transition: opacity 0.5s ease;
}

.servicios-verticales-wrapper.active {
  opacity: 1;
}

.content-section {
  flex: 1;
  max-width: 45%;
  padding-right: 4vw;
  opacity: 0;
  transform: translateX(-50px);
  animation: slideIn 0.8s ease forwards;
  z-index: 1;
}
.title {
  font-size: 50px;
  font-size: clamp(36px, 5vw, 56px);
  margin-bottom: clamp(20px, 3vw, 32px);
  font-weight: 800;
  color: var(--text-color);
  position: relative;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.2;
  max-width: 100%;
}

@media (max-width: 768px) {
  .title {
    font-size: clamp(30px, 4vw, 40px);
    line-height: 1.3;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 15px;
    white-space: pre-wrap;
    word-wrap: break-word;
    height: auto;
  }
}

.description {
  font-size: clamp(16px, 1.8vw, 20px);
  line-height: 1.7;
  margin-bottom: clamp(28px, 3.5vw, 36px);
  color: var(--text-secondary);
  opacity: 0;
  transform: translateY(20px);
  animation: fadeUp 0.8s ease forwards 0.3s;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(10px, 1.2vw, 18px);
  margin-top: clamp(28px, 3.5vw, 36px);
  opacity: 0;
  transform: translateY(20px);
  animation: fadeUp 0.8s ease forwards 0.6s;
}

.tag {
  background: var(--tag-bg);
  padding: clamp(8px, 1vw, 12px) clamp(16px, 2vw, 24px);
  border-radius: 8px;
  font-size: clamp(13px, 1.3vw, 15px);
  color: var(--primary-color);
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.button {
  background: var(--primary-color);
  border: none;
  color: var(--button-text);
  padding: clamp(12px, 1.4vw, 16px) clamp(24px, 2.5vw, 32px);
  font-size: clamp(15px, 1.6vw, 18px);
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 8px;
  margin-top: 3vw;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeUp 0.8s ease forwards 0.9s;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  background: var(--primary-color-hover);
}

.image-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 55%;
  opacity: 0;
  transform: translateX(50px);
  animation: slideIn 0.8s ease forwards 0.3s;
}

.image-section img {
  width: 100%;
  height: auto;
  max-width: 800px;
  object-fit: contain;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.image-section:hover img {
  transform: scale(1.02) translateY(-5px);
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .servicios-verticales-wrapper {
    padding: 4vw;
    gap: 4vw;
  }
  .content-section,
  .image-section {
    max-width: 45%;
  }
}

@media (max-width: 768px) {
  .servicios-verticales-wrapper {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 6vw;
    height: 100%;
    overflow-y: hidden;
  }
  .content-section,
  .image-section {
    max-width: 100%;
    overflow: hidden;
  }
  .content-section {
    height: auto;
    min-height: 0;
  }
  .image-section {
    height: auto;
  }
  .image-section img {
    max-width: 90%;
    max-height: 40vh;
  }
  .title::after {
    left: 50%;
    transform: translateX(-50%);
  }
  .button {
    align-self: center;
    width: 100%;
  }
  .tags-container {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .servicios-verticales-wrapper {
    padding: 4vw;
  }
  .title {
    font-size: clamp(28px, 4vw, 42px);
  }
  .description {
    font-size: 1rem;
  }
  .tag {
    font-size: 0.875rem;
    padding: 0.4rem 0.8rem;
  }
}
