.header-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .header-header {
    padding: 0;
    justify-content: space-around;
  }
}

.logo {
  cursor: pointer;
  display: flex;
  gap: 2px;
  align-items: flex-end;
  justify-items: center;
  padding-left: 10rem;
}
.logo-img {
  width: 40px;
  height: 45px;
}
.logo-name {
  font-family: "Google Sans Regular";
  font-weight: bold;
  padding: 0 10px;
  letter-spacing: 3px;
}
@media (max-width: 768px) {
  .logo {
    padding-left: 0;
  }
}

.menu-icon {
  display: none;
}
@media (max-width: 768px) {
  .menu-icon {
    cursor: pointer;
    display: inline-block;
    padding: 28px 20px;
    position: relative;
    user-select: none;
  }
}

.ul-menu {
  font-family: "Google Sans Regular";
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
  display: flex;
  list-style: none;
  gap: 1rem;
  margin: 0;
  padding: 0;
  align-items: center;
}
.homei {
  margin: 10px;
  transition: all 0.2s ease-in-out;
  color: #ffffff;
  padding: 0.5rem;
  cursor: pointer;
}

.homei:hover {
  box-shadow: 0 2px 10px #2ab0ee;
  background-color: #2ab0ee;
  color: #ffffff !important;
}
